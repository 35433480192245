import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1500.000000 1500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1500.000000) scale(0.100000,-0.100000)">
					<path d="M9076 5570 c-78 -31 -129 -89 -147 -167 l-7 -33 62 0 c61 0 63 1 79
35 9 19 31 44 49 55 40 24 116 26 162 5 58 -28 83 -105 53 -165 -19 -38 -91
-70 -159 -70 l-58 0 0 -54 0 -53 68 -5 c95 -7 140 -31 164 -86 62 -140 -73
-259 -219 -192 -43 19 -83 69 -83 103 0 14 -10 17 -66 17 l-66 0 7 -32 c30
-139 125 -211 280 -212 110 0 160 16 216 73 55 54 74 109 66 193 -8 90 -49
149 -127 184 l-25 12 25 10 c39 15 98 86 106 127 3 20 4 61 2 91 -6 65 -41
116 -109 154 -61 34 -201 40 -273 10z"/>
					<path d="M9819 5577 c-99 -28 -161 -92 -195 -200 -25 -81 -30 -304 -10 -408
36 -179 119 -252 286 -253 135 -1 215 45 262 148 31 69 32 200 2 266 -43 94
-125 144 -234 144 -82 0 -132 -17 -183 -63 l-40 -36 6 71 c8 85 22 128 55 173
66 88 217 79 262 -16 14 -31 18 -33 73 -33 65 0 69 7 38 75 -23 50 -74 99
-124 120 -52 22 -143 27 -198 12z m161 -429 c57 -30 83 -80 83 -158 0 -98 -51
-159 -141 -168 -99 -9 -172 41 -193 132 -34 153 111 265 251 194z"/>
					<path d="M5410 5145 l0 -435 65 0 65 0 0 45 c0 52 7 55 41 19 51 -56 159 -84
252 -64 147 30 238 158 238 331 0 138 -59 242 -168 296 -103 50 -255 30 -324
-43 -12 -13 -26 -24 -31 -24 -4 0 -8 70 -8 155 l0 155 -65 0 -65 0 0 -435z
m420 90 c126 -65 149 -268 42 -369 -81 -75 -225 -60 -286 31 -56 82 -56 191 0
275 49 76 161 105 244 63z"/>
					<path d="M10492 5566 c-131 -43 -195 -176 -196 -411 0 -145 21 -249 67 -326
26 -43 44 -59 91 -84 53 -27 69 -30 145 -30 175 1 256 82 292 291 17 101 7
293 -21 376 -26 78 -75 140 -133 170 -53 26 -183 34 -245 14z m181 -121 c40
-19 73 -67 86 -125 14 -60 14 -286 0 -350 -20 -95 -92 -148 -186 -137 -107 12
-153 106 -153 312 0 217 54 315 172 315 28 0 64 -7 81 -15z"/>
					<path d="M3960 5120 l0 -410 65 0 65 0 0 165 0 165 54 0 55 0 93 -165 93 -164
78 -1 79 0 -25 43 c-14 23 -59 99 -100 169 l-76 128 47 20 c95 42 136 107 137
215 0 88 -29 146 -96 191 -63 42 -134 54 -316 54 l-153 0 0 -410z m363 286
c45 -18 69 -59 70 -115 1 -107 -49 -143 -198 -145 l-100 -1 -3 138 -3 137 101
0 c62 0 113 -5 133 -14z"/>
					<path d="M4838 5357 c-98 -28 -183 -117 -209 -220 -19 -76 -7 -202 23 -263 32
-62 88 -117 148 -147 93 -45 245 -21 310 49 33 34 40 30 40 -21 l0 -45 65 0
65 0 0 325 0 325 -65 0 -65 0 0 -45 c0 -25 -2 -45 -4 -45 -3 0 -18 12 -33 26
-63 60 -184 86 -275 61z m197 -125 c64 -31 102 -89 112 -170 23 -201 -195
-325 -331 -188 -170 169 9 462 219 358z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
